import React, { useState } from "react";

import HomeGameTypeCard from "./homegametypecard";
import EmblaCarousel from "../embla";

export default function HomeGameTypes({ types, showOtherGames, showHeading, showDetails, currentCity }) {
    const [gameTypes, setGameTypes] = useState(types);
    if (!types || types.length == 0) {
        return null;
    }
    if (showOtherGames) {
        if (!types.filter((i) => !i.id).length) {
            types.unshift({ id: 0, name: "Другие игры" });
            setGameTypes(types);
        }
    }
    const slides = types.map((type) => {
        return (
            <HomeGameTypeCard
                type={type}
                showDetails={showDetails}
                key={type.id}
                currentCity={currentCity}
                rulesLink={true}
            />
        );
    });
    return (
        <div className="container-fluid">
            <div className="game-types">
                {showHeading && (
                    <>
                        <h3 className="d-mobile-only">
                            <b>Мы проводим игры</b>
                        </h3>
                        <h3 className="d-desktop-only">
                            <b>Какие игры мы проводим</b>
                        </h3>
                    </>
                )}
                <EmblaCarousel slides={slides} viewportClass={"game-types__cards"} />
            </div>
        </div>
    );
}
